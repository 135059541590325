/* change the colors */
$primary: #0267d4;
$danger: #c02d33;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~bootstrap/scss/bootstrap";

// @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");
@import "_avatar.scss";
//@import "new-ui.scss";
//@import "_common.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

$header-height: 60px;
$brand-colour: #c02d33;
$top-spacing: 20px;

$theme-dark: $dark;

// bootstrap override
.badge {
  font-weight: 500;
}
.form-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $brand-colour;
}
.form-label {
  margin-bottom: 0.25rem;
}

body {
  font-family: "Roboto", sans-serif;
}

.main-header {
  padding: 9.5px 15px;
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  height: $header-height;
}

.main-middle-sec {
  padding-top: $header-height + $top-spacing;
}

.v3-master {
  padding-top: $header-height + $top-spacing;
}

.btn-link {
  text-decoration: none;
}

.fs-7 {
  font-size: 0.875rem;
}
.fw-medium {
  font-weight: 500;
}

// header css

// alert btn
.alert {
  .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 0.675rem;
  }
}

//health care section
.ready_provider {
  overflow: hidden;
  .healthc_list {
    .healthc {
      height: 100px;
      width: 100px;
      text-align: center;
      padding: 10px;
      color: $theme-dark;
      text-decoration: none;
      display: block;
      &:hover {
        background-color: rgba($color: $dark, $alpha: 0.03);
      }
      .healthc_icon {
        margin: 10px 0 0 0;
        height: 34px;
        width: 34px;
        display: inline-block;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .healthc_name {
        font-size: 0.875rem;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 5px;
      }
    }
  }
}

.xcare_ad {
  width: 400px;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;

  .ad_item {
  }
  .slick-dots {
    bottom: 10px;
    li {
      margin: 0;
      button:before {
        color: #fff;
      }
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .carousel-indicators [data-bs-target] {
    height: 5px;
    width: 5px;
  }
}

// card sections

.info_cards {
  .card_item {
    height: 100%;
    &:hover {
      background-color: rgba($color: $dark, $alpha: 0.03);
    }
    .infocard_header {
      font-size: 1.125rem;
    }
    .card_desc {
      font-size: 0.875rem;
      line-height: 18px;
    }
  }
}

.staticad {
  .card_ititle {
    font-size: 1.5rem;
    color: $brand-colour;
    font-weight: 500;
    line-height: 34px;
  }
  .card_desc {
    font-size: 1rem;
    color: $brand-colour;
  }
}

// avatar with content

.av-txt {
  .av_title {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .av_subtitle {
    font-size: 0.875rem;
  }
}

// appointments

.appointments_cat {
  h5 {
    font-size: 1.125rem;
  }
  .upcomming_appo,
  .complete_appo,
  .cancelled_appo {
    .appo_card {
      min-height: 225px;
    }
  }
}

.appo_card {
  .app_status {
    position: absolute;
    right: 1.5rem;
    top: -0.75rem;
    .badge {
      font-size: 0.675rem;
    }
  }

  .short_action {
    position: absolute;
    right: 1.5rem;
    top: 2rem;
    ul {
      li {
        margin-bottom: 10px;
        .btn {
          height: 34px;
          width: 34px;
          border: 1px solid $theme-dark;
          padding: 7px 0;
          span {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

// offcanvas css

.offcanvas {
  .offcanvas-action {
    padding: 10px 30px;
  }

  .offcanvas-header {
    padding: 0 30px 10px 30px;
    .offcanvas-title {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
  .offcanvas-profile {
    padding: 0 30px 10px 30px;
    text-align: center;
  }
  .offcanvas-body {
    padding: 10px 30px;
  }

  .offcanvas-subtitle {
    font-size: 1.125rem;
    margin-bottom: 15px;
  }
  .offcanvas-other {
    padding: 0 30px 10px 30px;
  }

  .my_family_drawer {
    .av-txt {
      .av_title {
        font-size: 1rem;
      }
    }
  }
}

.btn-upload {
  background: rgba($color: $primary, $alpha: 0.1);
  color: $primary;
  border: 1px dashed $primary;
}

// appointment drawer
.offcanvas {
  .all_appo_drawer {
    .appo_card {
      margin-bottom: 30px;
    }
  }
}

// tab UI

.cs_tab {
  .nav-item {
    flex: 1 1 auto;
    .nav-link {
      display: block;
      text-align: center;
      color: rgba($color: $theme-dark, $alpha: 0.5);
      text-decoration: none;
      border: none;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      padding: 10px 10px;
      width: 100%;
      &.active {
        color: $primary;
        border-bottom: 3px solid $primary;
      }
    }
  }
}

// digi forms

.digi_forms {
  .digiform_list {
    .form_title {
      font-size: 1.25rem.125rem;
      font-weight: 500;
    }
    ul {
      padding: 10px 0 20px 0;
      border-bottom: 1px solid rgba($color: $theme-dark, $alpha: 0.1);
      li {
        a {
          color: $theme-dark;
          text-decoration: none;
          padding: 2px 0;
          display: block;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

// profile drawer

.prof_block {
  margin-bottom: 30px;
  .profile_title {
    font-size: 1.25rem.125rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

//coverage list

.coverages_list {
  margin-bottom: 40px;
  .coverage_name {
    font-size: 1.25rem.125rem;
    font-weight: 500;
  }
  .coverage_item {
    margin-bottom: 15px;
    .progress {
      overflow: visible !important;
      .progress-bar {
        position: relative;
        overflow: visible !important;
        &::after {
          display: block;
          height: 16px;
          width: 16px;
          background-color: #fff;
          border: 1px solid rgba($color: $theme-dark, $alpha: 0.2);
          content: "";
          clear: both;
          position: absolute;
          right: 0;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
        }
      }
    }
  }
}

//lootboxtop

.lootbox_top {
  text-align: center;
  .xcode {
    margin: 20px 0;
    .xcode_copy {
      background: $white;
      border: 1px dashed rgba($color: $theme-dark, $alpha: 0.5);
      text-transform: uppercase;
      padding: 10px 20px;
    }
  }
}

// my appointment corousel

.myappointment_coro {
  .appo_card {
    margin-top: 10px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    color: rgba($color: $dark, $alpha: 0.5);
    top: -44px;
    bottom: inherit;
    width: inherit;
    right: 0;
    i {
      font-size: 1.25rem;
    }
  }

  .carousel-control-prev {
    left: inherit;
    right: 34px;
  }
  .carousel-indicators {
    display: none;
  }
}

// prescreening model

.model_covid {
  .covdque {
    margin: 0 0 30px 0;
  }
  .yes-no {
    margin: 0 10px 30px;
    span {
      margin: 0 0 0 10px;
    }
    input {
      display: none;
    }
    label {
      height: 50px;
      width: 50px;
      background-color: $white;
      border-radius: 50%;
      margin: 0;
      line-height: 45px;
      border-width: 1px;
      border-style: solid;
      cursor: pointer;
      text-align: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
    .l-no {
      border-color: $danger;
    }
    .l-yes {
      border-color: $success;
    }
    input:checked ~ label.l-no {
      background-color: rgba($color: $danger, $alpha: 0.1);
    }
    input:checked ~ label.l-yes {
      background-color: rgba($color: $success, $alpha: 0.1);
    }
  }
  .deslist {
    text-align: left;
    margin: 30px 0 30px 0;
  }
  .ysnrad {
    text-align: center;
  }
  .andpbtn {
    text-align: center;
    margin: 30px 0 0 0;
  }
}

// refer friend popup css

.sh_btn {
  border-top: 1px solid rgba($color: $theme-dark, $alpha: 0.1);
  border-bottom: 1px solid rgba($color: $theme-dark, $alpha: 0.1);
  .sh_btn_item {
    text-align: center;

    .btn-trans {
      .icon {
        width: 40px;
        height: 40px;
        display: block;
        border: 1px solid rgba($color: $theme-dark, $alpha: 0.1);
        font-size: 1.25rem;
        line-height: 40px;
        margin: 0 auto 5px auto;
      }
    }
  }
}

// common popup css

.newcommon {
  &.addnewappt {
    .modal-dialog {
      max-width: 550px;
    }
  }

  .modal-header {
    margin: 0 30px 0 30px;
    padding: 20px 0 20px 0;
    .modal-title {
      font-size: 1.5rem;
      line-height: 30px;
    }
  }

  .modal-content {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
  .modal-body {
    padding: 20px 30px;
  }
  .modal-footer {
    border: none;
    padding: 0 30px 30px 30px;
  }
}

// date picker overright

.miuif_margin {
  .MuiInput-formControl {
    margin: 0;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .MuiInput-underline::after,
  .MuiInput-underline::before {
    display: none;
  }
  .MuiInputBase-input {
    padding: 0;
    height: auto;
  }
  .MuiIconButton-root {
    padding: 0;
  }
}

// stripe element

.stp_element {
  .form-control {
    padding: 0.575rem 0.75rem;
  }
}

// patient search iframe display full

.searchpframe {
  height: calc(100vh - 60px);
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-df-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// Resize new_and_ex offcanvas
.offcanvas {
  &.new_and_ex {
    height: 60px;
  }
}

// Otp popup

.newcommon {
  &.otp_popup {
    .modal-dialog {
      max-width: 350px;
    }
  }
}
.code_input {
  input {
    margin: 0 5px !important;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}

// call-strip
.call-strip {
  background: #000;
  padding: 10px 20px;
  top: $header-height;
  width: 100%;
}

.callpop {
  .modal-dialog {
    max-width: 250px;
  }
  .modal-body {
    padding: 50px;
  }
  .modal-content {
    background: #000;
  }
  .btn {
    height: 44px;
    width: 44px;
    padding: 10px 0;
  }
}
.modal-background-blur{
  background-color: #000000c7;
}

// View password icons
.view-password-control{
  display: flex;
  i {
    display: none;
    cursor: pointer;
    &.active{
      display: block;
    }
  }
}
.to-right-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}