$font-color: #4A494A;
$secondary-color : #e62b4a;
$aqua-color: #fff;
$primary-color: #2E88CD;
$title-color:#777F88;
$content-color:#95989A;
@mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin: auto;
}

.section-title {
	font-size: 28px;
	font-weight: 700;
	line-height: 36px;
	margin: 0 0 15px;
	color: #4a494a;
}

.services-offered {
	.media {
		position: relative;
		padding: 12px 0;
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 1px;
			top: 0;
			bottom: 0;
			right: -30px;
			left: auto;
			margin: auto 0;
			background-color: #E9E9E9;
		}
		.media-img {
			width: 40px;
			height: 40px;
			padding: 2px;
			text-align: center;
		}
		.media-body {
			font-size: 16px;
			font-weight: 700;
			color: #4A494A;
			span {
				color: #95989A;
				display: block;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px;
			}
		}
	}
	.service-selected-list {
		margin: 0;
		padding: 0 0 0 30px;
		li {
			font-size: 16px;
			font-weight: 700;
			color: #4A494A;
		}
	}
	.btn {
		padding-left: 0;
		padding-right: 0;
	}
	.all-services {
		position: relative;
		z-index: 0;
		.close-list {
			position: absolute;
			top: -20px;
			right: 0;
			left: auto;
			bottom: auto;
			margin: 0;
		}
		.all-service-list {
			-moz-column-count: 2;
			-moz-column-gap: 20px;
			-webkit-column-count: 2;
			-webkit-column-gap: 20px;
			column-count: 2;
			column-gap: 20px;
			margin: 0;
			padding: 0;
			li {
				font-size: 18px;
				font-weight: 700;
				color: #4A494A;
				padding: 4px 0;
			}
			li.selected {
				text-decoration: underline;
				color: #16CF50;
			}
		}
	}
}

.practician-name {
	.trusted-txt {
		font-size: 16px;
		color: #e62b4a;
		font-weight: 400;
		margin: 0 0 10px 0;
	}
	.p-name {
		font-size: 34px;
		font-weight: 700;
		line-height: 50px;
	}
	.p-address {
		color: #95989A;
	}
	.ratting-value {
		font-size: 10px;
		font-weight: 700;
		color: #95989A;
		strong {
			font-size: 40px;
			color: #4A494A;
		}
		span {
			font-size: 11px;
			color: #4A494A;
			font-weight: 400;
		}
	}
	.ratting-star {
		i {
			color: #FF8C00;
			margin: 0 2px 0 0;
			font-size: 14px;
		}
		span {
			color: #95989A;
			font-size: 14px;
			margin: 0 0 0 7px;
		}
	}
}
.helpful-review {
	position: relative;
	padding: 0 30px;
	 
	.title-txt {
		font-size: 18px;
		font-weight: 700;
		color: #FF8C00;
	}
	p {
		font-style: italic;
		margin: 15px 0 0 0;
		padding: 0 0 15px 0;
	}
}

.practice_at {
    margin: 30px 0 20px;
    .pfon {
        font-weight: 700;
    }
}

.profile-description-seo {
    hr {
        margin: 40px 0;
    }
}

.img-gallery {
	.img-box {
		background-color: #fff;
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		border: 1px solid #fff;
		img {
			max-width: 100%;
		}
	}
}
.open-hours {
	margin: 30px 0;
	background-color: #FFFFFF;
	box-shadow: 0px 3px 10px #0000000D;
	.title-sec {
		background-color: #DE443626;
		color: #e62b4a;
		text-align: center;
		padding: 25px;
		font-size: 18px;
		line-height: 18px;
		font-weight: 700;
		border: 1px solid #DE443626;
		border-radius: 3px 3px 0 0;
	}
	.desc-sec {
		padding: 25px 40px;
		border: 1px solid #E9E9E9;
		border-radius: 0 0 3px 3px;
		border-top: none;
		ul {
			li {
				display: flex;
				-ms-flex-pack: justify !important;
				justify-content: space-between !important;
				position: relative;
				margin: 15px 0;
				color: #95989A;
				font-weight: 400;
				font-size: 16px;
				&::after {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: #E9E9E9;
					top: auto;
					bottom: 7px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
				span {
					background: #fff;
					display: inline-block;
					position: relative;
					z-index: 1;
					&:first-child {
						width: 44px;
						text-align: left;
					}
					&:last-child {
						text-align: right;
						padding: 0 0 0 15px;
					}
				}
			}
			li.active {
				color: #16CF50;
				font-weight: 700;
			}
		}
	}
}

.timeline-sec
{
    position: relative;
    z-index: 0;
    z-index: 0;
    overflow: hidden;
    padding: 75px 0 0 0;
    min-height: 400px;
    background-size: cover;
    &::after
    {
        content: '';
        position: absolute;
        top:0;
        bottom: 0;
        margin: auto 0;
        left: 0;
        z-index: 1;
        width: 50%;
        height: 100%;
        background: -moz-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(128,128,128,0.7)), color-stop(100%, rgba(0,0,0,0)));
        background: -webkit-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: -o-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: -ms-linear-gradient(left, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        background: linear-gradient(to right, rgba(128,128,128,0.7) 0%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#808080', endColorstr='#000000', GradientType=1 );
    }   
}
.profile-sec-seo
{
    background-color: #F9F9F9;
    padding: 0;
    .practician-name
    {
        .media-img
        {
            background-color: #fff;
            height: 220px;
            width: 220px;
            border: 1px solid #E9E9E9;
            border-radius: 5px;
            padding: 5px;
            position: relative;
            top:-60px;
            box-shadow: 0px 3px 10px #0000000D;
            &::after
            {
                content: '';
                width: 44px;
                height: 44px;
                background-color: $secondary-color; 
                border: 1px solid #E9E9E9;
                border-radius: 50%;
                position: absolute;
                bottom: -22px;
                right: 22px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../../assets/images/ic_business_24px.svg);
            }
        }
        .media-body
        {
            margin: 30px 0;
        }
    }
}
.hygiene_rating {
	color: #5cb85c;
	font-size: 16px;
	font-weight: 600;
	margin: 10px 0 0;
	display: inline-block;
}

.act-cl {
    border: 1px solid #E9E9E9;
    border-radius: 3px;
    background-color: #fff;
    padding: 20px 30px;
    position: relative;
    margin: 0 0 30px 0;
    .switch {
        label {
            margin: 0;
        }
    }
}
.abt-sec
{
    .title-txt
    {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 15px 0;
    }
    .read-more
    {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-decoration: underline;
        color: $secondary-color;
        padding: 0;
        margin: 0 0 30px 0;
    }
}
.provider-list
{
    background: transparent linear-gradient(0deg, #FFFFFF00 0%, #F9F9F9 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9E9;
    border-radius: 5px 5px 0px 0px;
    margin: 45px 0 0 0;
    hr 
    {
        margin: 0;
    }
    .title-txt
    {
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        color: $title-color;
        padding:30px;
    }
    .list-sec-seo
    {
        padding: 15px 30px;
    }
    .dr-box
    {
        margin: 15px 0;
        .media-img 
        {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            overflow: hidden;
        }
        .dr-name
        {
            font-weight: 700;
            color: #4A494A;
        }
        .dr-desg
        {
            font-size: 14px;
            color: #999;
        }
        .dr-rating
        {
            font-size: 12px;
            .ratting-star
            {
                display: inline-block;
                img 
                {
                    height: 10px;
                    width: auto;
                }
            }
        }
    }

}
.insurance-btn
{
    border: 1px solid #E9E9E9;
    border-radius: 3px;
    background-color: #fff;
    padding: 15px 75px 15px 45px;
    position: relative;
    cursor: pointer;
    &::after
    {
        content: '';
        width: 100%;
        height: 100%;
        @include overlay();
        background-image: url(../../assets/images/ic_chevron_right_24px.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 30px) center;
    }
    .media-body
    {
        font-weight: 600;
        font-size: 18;
        line-height: 18px;
    }
}
.map-sec-client
{
    box-shadow: 0px 3px 10px #0000000D;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    overflow: hidden;
    iframe 
    {
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) 
{
    .profile-sec-seo .practician-name.media
    {
        display: flex;
        width: 100%;
        .trusted-txt
        {
            margin-bottom: 7px;
        }
    }
    .profile-sec-seo .practician-name .media-body
    {
        margin: 15px 0;
    }
    .practician-name .p-name
    {
        font-size: 30px;
        line-height: 40px;
    }
    .practician-name .ratting-value
    {
        margin-top: 7px!important;            
    }
    .abt-sec
    {
        margin-top: 45px;
    }
    .profile-sec-seo .practician-name .media-img
    {
        height: 160px;
        width: 160px;
        padding:10px;
        margin-right: 30px!important;
    }
    .profile-sec-seo.for_provider
    {
        .practician-name + .btn-custom
        {
            
            margin-bottom: 30px;
            padding: 12px 15px;
        }
    }
    .practician-name .ratting-value strong
    {
        font-size: 30px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) 
{

    .profile-sec-seo .practician-name .media-img
    {
        width: 90px;
        height: 90px;
        padding: 15px; 
            
    }
    .profile-sec-seo .practician-name .media-img::after
    {
        height: 30px;
        width: 30px;
        bottom: -14px;
        right: 14px;
        background-size: auto 12px;
    }
    .profile-sec-seo .practician-name 
    {
        .media-body
        {
            margin: 5px 0 0 15px;
            .p-name
            {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    .insurance-btn
    {
        padding: 15px 75px 15px 15px;
        margin: 30px 0 30px 0;
    }
    .profile-description-seo
    {
        .cd-inner-section
        {
            margin-top: 30px;
        }
    }
    .profile-sec-seo.for_provider
    {
        .practician-name + .btn-custom
        {
            max-width: 300px;
            margin-bottom: 30px;
        }
    }
}