.have_insurance
{
    box-shadow: 0px 3px 10px #0000000D;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    background-color: #fff;
    padding: 45px 30px;
    text-align: center;
    margin: 0 0 60px 0;
    .radio-box
    { 
        width: 100%;
        max-width: 160px;
        text-align: center; 
        input 
        {
            display: none;
        }
        label 
        {
            width: 100%;
            .icon-value 
            {
                height: 50px;
                width: 50px;
                background-color: #fff;    
                border-radius: 50%;
                margin: 0;
                line-height: 42px;
                border-width: 2px;
                border-style: solid;
                cursor: pointer;
                display: block;
                margin: 0 auto 7px auto;
            }
        }
        .l-no .icon-value
        {
            border-color: #FC1D1D;
        }
        .l-yes .icon-value
        {
            border-color: #16CF50;
        }
        input:checked ~ label.l-no .icon-value
        {
            background-color: #DE44361A;
        }     
        input:checked ~ label.l-yes .icon-value
        {
            background-color: #00C9A726;
        } 
        .radio-value
        {
            width: 100%;
            display: block;
            font-weight: 400;
        }
    }
}
.form-title-txt
{
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 45px 0;
    color: #4A494A;
    width: 100%;
    text-align: center;
}
.form-outer-box
{
    box-shadow: 0px 3px 10px #0000000D;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    background-color: #fff;
    padding: 30px;
    margin: 0 0 60px 0;   
    hr 
    {
        margin: 30px 0;
    }
    label
    {
        color: #95989A;
        font-weight: 700;
        font-size: 16px;
        margin: 0 0 7px 0;
    }
    input 
    {
        background-color: #fff;
        padding:12px 15px 8px 15px;
        border: 1px solid #E9E9E9;
        border-radius: 3px;
        color: #4A494A;
        line-height: 26px;
        height: auto;
    }

    select 
    {
        padding: 13px 15px 9px 15px;
        height: auto;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url(../../assets/images/dropdown-black.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 12px;
        cursor: pointer;
    }
    .btn 
    {
        margin: 7px 0 0 0;
        padding: 12px 15px;
    }
    .btn-save
    {
        background-color: #E62B4A;

    }
    .btn-cancel
    {
        background-color: #4A494A;
    }            
}

.form-value-box
{
    background-color: #fff;
    padding:12px 15px 8px 15px;
    border: 1px solid #E9E9E9;
    border-radius: 3px;
    margin: 0 0 15px 0;
    .media-body
    {
        div
        {
            display: inline-block;
            width: auto;
            margin: 0 10px;
            font-size: 16px;
            span 
            {
                display: block;
                color: #95989A;
                font-size: 12px;
            }
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .have_insurance
    {
        padding: 30px 15px;
        .radio-box
        {
            max-width: 110px;
        }
        .form-title-txt
        {
            font-size: 20px;
            line-height: 28px;
        }
    }
    .form-outer-box
    {
        padding: 30px 15px;
        .form-title-txt
        {
            font-size: 20px;
            line-height: 28px;
        }
        .form-value-box
        {
           .varification-status
            {
                font-size: 12px;
            }
            .varification-status.verified , .varification-status.verify
            {
                margin: 5px 3px;
            }
        }
        .btn
        {
            font-size: 14px;
            line-height: 14px;
        }
    }
}