ul,
ol {
	padding-left: 0 !important;
}
li {
	list-style: none;
}
/* HEADINGS */
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
	font-weight: 700;
}
h1 {
	font-size: 3.75rem;
	line-height: 4.688rem;
	font-weight: 300;
}
h2 {
	font-size: 3.125rem;
	line-height: 4.063rem;
	font-weight: 300;
}
h3 {
	font-size: 24px;
	line-height: 38px;
	font-weight: 300;
}
h4 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 300;
}
h5 {
	font-size: 18px;
	font-weight: 300;
}
.standard-button,
.standard-button2 {
	font-size: 18px;
	font-weight: 700 !important;
	border-radius: 4px !important;
	text-shadow: 0 !important;
	color: #ffffff;
	min-width: 200px;
	border: none;
	padding: 16px 25px 16px 25px;
	margin: 5px;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
	background-color: #e62b4a;
}
.standard-button:hover,
.standard-button2:hover {
	border: none;
	color: #fff;
}
.standard-button i,
.standard-button2 i {
	vertical-align: inherit;
	margin-right: 8px;
	font-size: 20px;
}
.section-header {
	padding-bottom: 78px;
}
.section-header h2 {
	margin-bottom: 20px;
}
.section-header .section-description {
	display: inline-block;
	position: relative;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}
.like-h1 {
	position: relative;
	padding-bottom: 20px;
	font-size: 40px;
	line-height: 50px;
}
.like-h1::after {
	content: "";
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	background-color: #2e88cd;
	height: 2px;
	width: 180px;
	position: absolute;
}
/* Common css end */

/* .header-landing & banner css start */
.header-landing {
	background: url(../images/images/dentist-banner.jpg) no-repeat center top fixed;
	-webkit-background-size: cover;
	background-size: cover;
}
.color-overlay {
	background: rgba(0, 0, 0, 0.75);
}
.header-landing .color-overlay {
	/* min-height: 900px; */
	padding-bottom: 60px;
}
.header-landing .full-screen {
	width: 100%;
	height: 100px;
}
.header-landing .only-logo .navbar {
	background: none;
	padding: 0;
}
.header-landing .only-logo .navbar .navbar-header {
	/* float: none; */
}
.header-landing .home-contents {
	margin-top: 5%;
}
.header-landing .intro-section {
	text-align: left;
}
.header-landing .intro-section .standard-button {
	margin: 0;
}
.header-landing .phone-image {
	max-width: 350px;
	margin: auto;
}
.header-landing .phone-image img {
	width: 100%;
}
.header-landing .intro-section .intro {
	color: #ffffff;
	margin-bottom: 36px;
	font-size: 52px;
	line-height: 64px;
}
.header-landing .intro-section h5 {
	color: #ffffff;
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 30px;
	font-weight: 500;
}
.header-landing .intro-section .buttons {
	margin-top: 35px;
}
.only-logo .navbar-header {
	/* margin: auto;
	text-align: center; */
}
.address-section {
	position: relative;
	z-index: 0;
	width: 100%;
	margin: auto;
}
.address-section .location-input {
	width: calc(100% - 40px);
	border-radius: 10px;
	padding: 16px 70px 16px 16px;
	border-color: transparent;
	color: #4a494a;
}
.address-section .location-btn {
	width: 76px;
	height: 76px;
	border: none;
	border-radius: 50%;
	background-color: #e62b4a;
	display: block;
	text-align: center;
	line-height: 76px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto 0;
}
.location-title {
	color: #fff;
	line-height: normal;
	font-size: 14px;
	display: block;
	margin: 15px 0;
	text-align: center;
}
.location-title:hover {
	color: #fff;
}
.location-title img {
	width: 16px;
	display: inline-block;
	margin: -2px 5px 0 0;
}
/* .header-landing & banner css end */

.features {
	padding-top: 80px;
	padding-bottom: 30px;
	background-color: #2e88cd;
}

.features .features-left {
	text-align: right;
	margin-top: 26px;
	margin-bottom: 80px;
}

.features .features-left .icon-container {
	float: right;
	margin-left: 20px;
}

.features .features-right {
	text-align: left;
	margin-top: 26px;
	margin-bottom: 80px;
}

.features .features-right .icon-container {
	float: left;
	margin-right: 20px;
}

.features .feature {
	margin-bottom: 40px;
}

.features .feature .icon-container {
	display: block;
	min-height: 150px;
	margin-top: 5px;
}

.features .feature .icon-container .icon {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	line-height: 55px;
	font-size: 18px;
	text-align: center;
	-webkit-transition: all ease-in 0.25s;
	transition: all ease-in 0.25s;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

.features .phone-image {
	max-width: 250px;
	margin: auto;
	margin-bottom: 80px;
}

.features .phone-image img {
	width: 100%;
}

.features .main-color {
	font-weight: 500;
	color: #fff;
	font-size: 18px;
}
.features .like-h1::after {
	background-color: #fff;
}
.features {
	color: #fff;
}
.features .feature .icon-container .icon {
	border: 1px solid #fff;
	color: #fff;
}
.features .feature:hover .icon-container .icon {
	background: #fff;
	color: #2e88cd;
	border: 2px solid #2e88cd;
	-webkit-box-shadow: 0px 0px 0px 2px #fff;
	box-shadow: 0px 0px 0px 2px #fff;
}

.app-brief {
	padding-top: 110px;
	padding-bottom: 60px;
}

.app-brief h2 {
	margin-bottom: 20px;
}

.app-brief .phone-image {
	max-width: 350px;
	margin: auto;
	margin-bottom: 50px;
}

.app-brief .phone-image img {
	width: 100%;
}

.app-brief .left-align {
	text-align: left;
	margin-bottom: 50px;
}

.app-brief .left-align p {
	display: block;
	float: none;
	margin-top: 50px;
}

.app-brief .left-align .feature-list {
	margin-top: 40px;
}

.app-brief .left-align .feature-list li {
	margin-bottom: 26px;
}

.app-brief .left-align .feature-list li i {
	margin-right: 10px;
	vertical-align: inherit;
}
.app-brief .app-btn {
	display: inline-block;
	height: 70px;
	width: auto;
	margin: 7px 20px 7px 0;
}
.app-brief .app-btn.play-store {
	margin-left: 20px;
}
.app-brief .app-btn img {
	width: auto;
	height: 100%;
}
.app-brief .like-h1::after {
	right: auto;
	margin: 0;
}
.app-brief .app-btn.play-store img {
	max-height: calc(100% - 2px);
}

.screenshots {
	padding-top: 80px;
	padding-bottom: 90px;
	background-color: #f8f8f8;
}
.screenshots .owl-theme .owl-nav
{
	display: none;
}
.screenshots .owl-theme .owl-dots
{
	margin-top: 30px;
}
.screenshots .shot {
	margin: 13px;
	border-radius: 0px;
	border: 1px solid #e1e1e1;
	-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	display: block;
}

.screenshots .owl-theme .owl-controls {
	margin-top: 30px;
}
.screenshots .shot img {
	border-radius: 4px;
	width: 100%;
}
footer {
	background-color: #4a494a;
}
.copyright-strip p.copyright {
	color: #fff;
	padding-top: 15px;
	padding-bottom: 30px;
	margin: 0;
}
.copyright-strip {
	padding-top: 30px;
}
.contact-txt {
	color: #fff;
	margin: 30px 0;
}
.contact-txt a {
	font-weight: 500;
	color: #fff;
	text-decoration: underline;
	font-size: 18px;
}
footer hr {
	border-color: #fff;
	opacity: 0.2;
}
.app-download-sec .app-btn {
	display: inline-block;
	height: 50px;
	width: auto;
	margin: 7px 20px 7px 0;
}

.app-download-sec .app-btn img {
    width: auto;
    height: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
	.like-h1 {
		font-size: 30px;
		line-height: 40px;
	}

	.header-landing .color-overlay {
		min-height: 780px;
	}
	.header-landing .intro-section .intro {
		font-size: 36px;
		line-height: 46px;
	}

	.header-landing .phone-image {
		max-width: 265px;
		margin-top: 5%;
	}

	.features .features-left,
	.features .features-right {
		margin-top: 0;
	}

	.features .features-left .icon-container,
	.features .features-right .icon-container {
		display: none;
	}
	.app-brief .app-btn {
		height: 45px;
	}
	.app-brief .app-btn.play-store {
		margin-left: 0;
	}
	.app-download-sec .app-btn {
		height: 44px;
	}
}

@media (max-width: 767px) {
	.hidden-xs {
		display: none;
	}

	.like-h1 {
		font-size: 30px;
		line-height: 40px;
	}
	.like-h1::after {
		width: 140px;
	}
	.section-header {
		padding-bottom: 30px;
	}
	.header-landing .color-overlay .standard-button {
		margin-bottom: 60px;
	}
	.header-landing .color-overlay {
		min-height: 580px;
	}

	.header-landing .only-logo .navbar {
		padding: 20px;
	}

	.header-landing .intro-section .intro {
		font-size: 36px;
		line-height: 46px;
		text-align: center;
		margin: 30px 0;
	}
	.header-landing .intro-section h5 {
		text-align: center;
	}
	.features .features-left,
	.features .features-right {
		text-align: center;
		margin-top: 5px;
		margin-bottom: 0;
	}

	.features {
		padding: 60px 0 30px 0;
	}

	.features .features-left .icon-container,
	.features .features-right .icon-container {
		float: none;
		margin-left: inherit;
		min-height: 70px;
	}

	.features .feature .icon-container .icon {
		margin: auto;
	}
	.screenshots {
		padding: 60px 0;
	}
	.screenshots .shot {
		margin: 15px auto;
		max-width: 280px;
	}
	.app-brief {
		padding: 60px 0 15px 0;
	}
	.app-brief .app-btn {
		height: 40px;
		margin: 7px;
	}
	.app-brief .app-btn.play-store {
		margin-left: 15px;
	}
	.app-brief .like-h1::after {
		right: 0;
		margin: auto;
	}
	.app-download-sec .app-btn {
		height: 40px;
		margin: 7px 10px;
	}
}

@media (max-width: 480px) {
	.header-landing .color-overlay {
		min-height: 480px;
	}

	.header-landing .only-logo .navbar {
		padding: 10px;
	}

	.features .feature:hover .icon-container .icon,
	footer .contact-button:hover {
		-webkit-background-clip: padding-box;
		-moz-background-clip: padding;
		background-clip: padding-box;
	}
}

